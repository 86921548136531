"use client";

import { Button } from "@/components";
import { BUTTON_CONSTANTS } from "@/utils";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const router = useRouter();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <main className="main page-404">
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
              <h1 className="display-3 mb-30">Oops! Something Went Wrong!</h1>
              <p className="font-lg text-grey-700 mb-30">
                It looks like there was an issue loading the products.
                <br />
                Please refresh the page or check back later. We appreciate your
                patience!
                <br />
              </p>
              <div className="d-flex flex-row justify-content-center gap-2">
                <Button
                  className="btn btn-default submit-auto-width font-xs hover-up mt-30"
                  type={BUTTON_CONSTANTS.BUTTON}
                  text={
                    <span>
                      <i className="fi-rs-rotate-right ml-0 mr-5"></i> Refresh
                      Page
                    </span>
                  }
                  onClick={() => {
                    router.refresh();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
